<template>
	<div class="commitdetails" v-loading="detailLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>添加评价</span>
				</div>
				<div class="content">
					<el-form-item label="评价商品：" required>
						<span style="color:#409EFF;margin-left:15px;cursor: pointer;" @click="handlePrizePro">选择商品</span>
						<div class="productInfo" style="display: flex;width: 100%;" v-if='proCheckedId'>
							<img :src="imgUrl + ProductInfo.ImgUrl +'@!cut64'" v-if='ProductInfo.ImgUrl' />
							<div style="display: flex;margin-left:10px;width: 60%;">
								<!-- <div style="color: #f00;font-size: 12px;width:40px" v-if="ProductInfo.ProductType==1">[组合]</div> -->
								<div style="width:90%;" class="ellipsisStyle">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{ProductInfo.ProductName}}</pre>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="评价内容" required>
						<el-input type="textarea" style="width: 800px;" resize='none' :rows="8" placeholder="请输入评价内容" v-model="ruleForm.textarea">
						</el-input>
					</el-form-item>

					<el-form-item label="评价星级" required>
						<el-rate v-model="ruleForm.rateValue" style="margin-top: 8px;">
						</el-rate>
					</el-form-item>
					
					<el-form-item label="添加评价图片(可选)">
						<el-upload :action="imgApi" list-type="picture-card"  :on-success="handleAvatarSuccessfu"
						 :limit="5" multiple accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu" :before-upload="beforeAvatarUpload">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		
		<!-- 选择商品-->
		<el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" class="dialog data-dialog">
			<select-produce :proCheckedId='proCheckedId'  @getSelection='getSelectPros'></select-produce>
		</el-dialog>
		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselEdit">取消</el-button>
			<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {commentSave} from '@/api/TurnTomySelf.js'
	
	import selectProduce from './selectCommitgoods'
	export default {
		components: {
			selectProduce
		},
		data() {
			return {
				proCheckedId:0,
				detailLoading: false,
				ruleForm: {
					textarea: '',
					rateValue: null,
					AttachedImgUrls:[]
				},
				rules: {
					textarea: [{
						required: true,
						message: '请输入评价内容',
						trigger: 'blur'
					}],
				},
				imgUrl: config.IMG_BASE,
				ProductInfo: {
					ImgUrl: '',
					// ProductType: 0,
					ProductName: '',
				},
				imgApi: config.UPLOAD_IMG,
				saveLoading:false,
				selectProShow:false
			}
		},
		created() {},
		methods: {
			canselEdit(){
				this.$router.push({
					path: '/goods/comment',
				})
			},
			getSelectPros(selection, isChecked) {
				if (isChecked) {
					this.selectProductlist = selection
					this.ProductInfo.ProductName = this.selectProductlist.ProductName
					// this.ProductInfo.ProductType = this.selectProductlist.ProductType
					this.ProductInfo.ImgUrl = this.selectProductlist.ImgUrl
					this.proCheckedId = this.selectProductlist.ProductSpecId
				}
				this.selectProShow = false
			},
			handlePrizePro() {
					this.selectProShow = true
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				this.ruleForm.AttachedImgUrls.push(file.response[0])
				console.log(this.ruleForm.AttachedImgUrls,'上传的图片list')
				// if (this.ruleForm.AttachedImgUrls.length > 4) {
				// 	this.$message({
				// 		type: 'error',
				// 		message: '最多只能上传5张图'
				// 	});
				// }
			
			},
			handleRemovefu(file, fileList) {
				this.ruleForm.AttachedImgUrls = this.ruleForm.AttachedImgUrls.filter(item => {
					return item != file.response[0]
				})
				console.log(this.ruleForm.AttachedImgUrls, 'kdhahdja')
			
			},
			
			// 图片长传-之前
			beforeAvatarUpload(file) {
				let self = this;
				let type_arr = ["image/jpeg", "image/png"];
				let type = file.type;
				if (!type_arr.includes(type)) {
					this.$message.error("图片格式不正确,只支持jpg和png类型图片");
					return false;
				}
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('已自动过滤大于1M的图片!');
					return false;
				}
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							if(this.proCheckedId == 0){
								this.$message({
									showClose: true,
									type: 'error',
									message: '请至少选择一件商品'
								})
								return
							}
							this.saveLoading = true
							let data = {
								ProductSpecId:this.proCheckedId,
								Content:this.ruleForm.textarea,
								PicUrls:this.ruleForm.AttachedImgUrls,
								Star:this.ruleForm.rateValue
							}
							
							let result = await commentSave(data)
							
							if(result.IsSuccess){
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								
								this.$router.push({
									path: '/goods/comment',
								})
							}
							
							
							
							
							
							
			
						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {
			
							this.saveLoading = false
						}
			
			
					} 
				});
			
			},
		}
	}
</script>

<style lang="less" scoped>
	.commitdetails {
		padding: 10px;
		background: #fff;
	}
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}
	
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
</style>
