<template>
	<!-- 此单选商品组件，只适用于单独规格的商品 -->
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style="width:200px" placeholder="商品名称、编码"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table :data="tableDataList" height='450px' style="width: 100%" ref="compSelectProTable" v-loading='loading'
			 :row-key="getRowKeys" empty-text="筛选结果为空">
				<el-table-column width="55">
					<template scope="scope">
						<el-radio :label="scope.row.ProductSpecId"  v-model="templateRadio" @change.native="getTemplateRow(scope.row)">{{&nbsp;}}</el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="Name" label="商品" width="300">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src='imgUrl+scope.row.ImgUrl' />
							<div class="right">
								<div class="name twoflows">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="SpecValue" label="规格">
					<template slot-scope="scope">
						<span v-if="scope.row.SpecValue">
							<span>{{scope.row.SpecValue}}</span>
						</span>
						<span v-else>默认规格</span>
						<span v-if="scope.row.SpecValue2">，{{scope.row.SpecValue2}}</span>
				
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button class="button" @click="saveSelection" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import config from '@/config/index'
	import {commentselectproduct}  from '@/api/TurnTomySelf.js'
	export default {
		name: 'selectProduce',
		props: {
			proCheckedId: {
				type: Number,
				default: () => {
					return 0
				}
			},
			Num:{
				type: Number,
				default: () => {
					return 0
				}
			},
			couponData: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {
				loading: false,
				imgUrl: config.IMG_BASE,
				searchKey: '',
				templateRadio: 0,
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selection: {},
				isChecked: false,
			}
		},
		created() {
			this.getSingleList()
		},
		mounted() {

		},
		methods: {
			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						Keywords : this.searchKey,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					};
					let result = await commentselectproduct(data)
					this.tableDataList = result.Result.Results
					this.page.total = result.Result.Total;
					this.templateRadio = this.proCheckedId
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			// IsCheckState(row) {
			// 	return row.IsDisabled
			// },
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val
				this.getSingleList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val
				this.getSingleList()
			},
			getRowKeys(row) {
				return row.id;
			},
			handleFilter() {
				
				this.page.current = 1
				this.getSingleList()
			},
			//单选按钮
			getTemplateRow(row) {
				this.isChecked = true
				this.selection = row
			},
			//关闭弹框，以及曝光选择
			saveSelection() {
				this.$emit('getSelection', this.selection, this.isChecked)
			}

		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
		::v-deep .el-table__row .el-radio__label{
			visibility: hidden!important;
		}
	}
	.twoflows{
		width: 180px;
		overflow:hidden; 
		text-overflow:ellipsis;
		display:-webkit-box; 
		-webkit-box-orient:vertical;
		-webkit-line-clamp:2; 
	}
	.content {
		overflow: hidden;
	}

	.dialog-footer {
		.button {
			width: 150px;
		}

		text-align: center;
		margin-top: 20px;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}
</style>
